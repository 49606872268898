import Header from '@/components/Header'
import ScheduleNotFound from '@/components/ScheduleNotFound'
import type { GetServerSideProps, NextPage } from 'next'
import Head from 'next/head'

interface Props {
  text?: string
  codAgendamento?: string
}

const Home: NextPage<Props> = ({ text, codAgendamento }) => {
  return (
    <div className="container mx-auto">
      <Head>
        <title>Checkin Saludia</title>
        <meta name="description" content="Sua consulta na Saludia!" />
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <Header />
      <ScheduleNotFound text={text} codAgendamento={codAgendamento} />
    </div>
  )
}

export const getServerSideProps: GetServerSideProps = async (context) => {
  let text = 'Não conseguimos encontrar o seu agendamento.'
  let codAgendamento = 'Não encontrado'
  const { error, id } = context.query

  if (error) {
    text = decodeURI(context?.query?.error as string)
  }

  if (id) {
    codAgendamento = id as string
  }

  return {
    props: { text, codAgendamento },
  }
}

export default Home
