import React from 'react'
import Divider from './Divider'
import Logo from './Logo'

const Header: React.FC = () => {
  return (
    <header className="mb-14">
      <div className="flex my-4 justify-between">
        <div className="mx-auto">
          <Logo width={132} height={42} />
        </div>
      </div>
      <Divider />
    </header>
  )
}

export default Header
