import { ThemeContext } from 'providers/theme'
import { useContext } from 'react'

export interface Props {
  width?: number
  height?: number
}

const Logo: React.FC<Props> = ({ width = 132, height = 42 }) => {
  const { theme } = useContext(ThemeContext)
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 201 61"
      fill="none"
    >
      <path
        d="M73.8614 42.806C72.2068 42.806 70.5891 42.6034 69.0086 42.1982C67.428 41.7677 66.1685 41.236 65.23 40.6029L67.1563 36.3486C68.0454 36.9311 69.1197 37.4122 70.3792 37.792C71.6388 38.1466 72.8736 38.3238 74.0837 38.3238C76.5287 38.3238 77.7512 37.7034 77.7512 36.4626C77.7512 35.8802 77.4178 35.4623 76.751 35.2091C76.0841 34.9559 75.0592 34.7406 73.6762 34.5634C72.0462 34.3101 70.7003 34.0189 69.6383 33.6897C68.5764 33.3605 67.6502 32.7781 66.86 31.9424C66.0944 31.1068 65.7116 29.9166 65.7116 28.3719C65.7116 27.0804 66.0697 25.9409 66.7859 24.9533C67.5268 23.9404 68.5887 23.1554 69.9717 22.5983C71.3794 22.0412 73.0341 21.7626 74.9358 21.7626C76.3435 21.7626 77.7388 21.9272 79.1218 22.2564C80.5295 22.5603 81.6903 22.9908 82.6041 23.5479L80.6777 27.7642C78.9243 26.7513 77.0103 26.2448 74.9358 26.2448C73.7009 26.2448 72.7748 26.4221 72.1574 26.7766C71.54 27.1311 71.2313 27.5869 71.2313 28.144C71.2313 28.7771 71.5647 29.2202 72.2315 29.4735C72.8983 29.7267 73.9602 29.9673 75.4173 30.1952C77.0473 30.4737 78.3809 30.7776 79.4182 31.1068C80.4554 31.4107 81.3569 31.9804 82.1225 32.8161C82.8881 33.6517 83.2709 34.8166 83.2709 36.3107C83.2709 37.5768 82.9004 38.7037 82.1595 39.6913C81.4186 40.6789 80.332 41.4512 78.8996 42.0083C77.4919 42.5401 75.8125 42.806 73.8614 42.806Z"
        className="fill-neutral-600"
      />
      <path
        d="M94.8752 21.7626C97.9623 21.7626 100.333 22.5223 101.988 24.0417C103.642 25.5357 104.47 27.8022 104.47 30.8409V42.5021H99.0613V39.9572C97.9746 41.8564 95.9495 42.806 92.9859 42.806C91.4547 42.806 90.1211 42.5401 88.985 42.0083C87.8737 41.4765 87.0217 40.7422 86.4289 39.8052C85.8362 38.8683 85.5399 37.8047 85.5399 36.6145C85.5399 34.7153 86.2314 33.2213 87.6144 32.1324C89.0221 31.0435 91.183 30.499 94.0972 30.499H98.6908C98.6908 29.2076 98.308 28.22 97.5424 27.5363C96.7768 26.8272 95.6284 26.4727 94.0972 26.4727C93.0353 26.4727 91.9857 26.65 90.9484 27.0045C89.9359 27.3337 89.0715 27.7895 88.3553 28.3719L86.2808 24.2316C87.3674 23.4466 88.664 22.8389 90.1705 22.4084C91.7017 21.9779 93.2699 21.7626 94.8752 21.7626ZM94.4306 38.8176C95.4185 38.8176 96.2952 38.5897 97.0608 38.1339C97.8264 37.6528 98.3698 36.9564 98.6908 36.0448V33.9556H94.727C92.3561 33.9556 91.1707 34.7533 91.1707 36.3486C91.1707 37.1083 91.4547 37.7161 92.0227 38.1719C92.6154 38.6024 93.4181 38.8176 94.4306 38.8176Z"
        className="fill-neutral-600"
      />
      <path
        d="M109.7 14.3177H115.479V42.5021H109.7V14.3177Z"
        className="fill-neutral-600"
      />
      <path
        d="M140.921 22.0665V42.5021H135.438V40.0711C134.673 40.9574 133.759 41.6411 132.697 42.1223C131.635 42.5781 130.487 42.806 129.252 42.806C126.634 42.806 124.559 42.0336 123.028 40.4889C121.497 38.9442 120.731 36.6525 120.731 33.6138V22.0665H126.51V32.7401C126.51 36.0321 127.856 37.6781 130.548 37.6781C131.931 37.6781 133.043 37.2223 133.882 36.3107C134.722 35.3737 135.142 33.9936 135.142 32.1704V22.0665H140.921Z"
        className="fill-neutral-600"
      />
      <path
        d="M166.575 14.3177V42.5021H161.056V40.1471C159.623 41.9197 157.549 42.806 154.832 42.806C152.955 42.806 151.251 42.3755 149.72 41.5145C148.213 40.6535 147.028 39.4254 146.164 37.83C145.299 36.2347 144.867 34.3861 144.867 32.2843C144.867 30.1825 145.299 28.3339 146.164 26.7386C147.028 25.1432 148.213 23.9151 149.72 23.0541C151.251 22.1931 152.955 21.7626 154.832 21.7626C157.376 21.7626 159.364 22.5856 160.796 24.2316V14.3177H166.575ZM155.832 37.944C157.289 37.944 158.5 37.4375 159.463 36.4246C160.426 35.3864 160.907 34.0063 160.907 32.2843C160.907 30.5623 160.426 29.1949 159.463 28.182C158.5 27.1438 157.289 26.6246 155.832 26.6246C154.351 26.6246 153.128 27.1438 152.165 28.182C151.202 29.1949 150.72 30.5623 150.72 32.2843C150.72 34.0063 151.202 35.3864 152.165 36.4246C153.128 37.4375 154.351 37.944 155.832 37.944Z"
        className="fill-neutral-600"
      />
      <path
        d="M171.924 22.0665H177.703V42.5021H171.924V22.0665ZM174.814 19.2177C173.752 19.2177 172.887 18.9011 172.22 18.2681C171.554 17.635 171.22 16.85 171.22 15.913C171.22 14.9761 171.554 14.1911 172.22 13.558C172.887 12.9249 173.752 12.6084 174.814 12.6084C175.875 12.6084 176.74 12.9123 177.407 13.52C178.073 14.1278 178.407 14.8875 178.407 15.7991C178.407 16.7867 178.073 17.6097 177.407 18.2681C176.74 18.9011 175.875 19.2177 174.814 19.2177Z"
        className="fill-neutral-600"
      />
      <path
        d="M191.105 21.7626C194.192 21.7626 196.563 22.5223 198.218 24.0417C199.872 25.5357 200.7 27.8022 200.7 30.8409V42.5021H195.291V39.9572C194.205 41.8564 192.179 42.806 189.216 42.806C187.685 42.806 186.351 42.5401 185.215 42.0083C184.104 41.4765 183.252 40.7422 182.659 39.8052C182.066 38.8683 181.77 37.8047 181.77 36.6145C181.77 34.7153 182.461 33.2213 183.844 32.1324C185.252 31.0435 187.413 30.499 190.327 30.499H194.921C194.921 29.2076 194.538 28.22 193.772 27.5363C193.007 26.8272 191.858 26.4727 190.327 26.4727C189.265 26.4727 188.216 26.65 187.178 27.0045C186.166 27.3337 185.301 27.7895 184.585 28.3719L182.511 24.2316C183.597 23.4466 184.894 22.8389 186.4 22.4084C187.932 21.9779 189.5 21.7626 191.105 21.7626ZM190.661 38.8176C191.648 38.8176 192.525 38.5897 193.291 38.1339C194.056 37.6528 194.6 36.9564 194.921 36.0448V33.9556H190.957C188.586 33.9556 187.401 34.7533 187.401 36.3486C187.401 37.1083 187.685 37.7161 188.253 38.1719C188.845 38.6024 189.648 38.8176 190.661 38.8176Z"
        className="fill-neutral-600"
      />
      <path
        d="M24.2622 7.95488C24.2622 3.56152 27.8237 0 32.2171 0H33.8679C37.3496 0 40.172 2.82241 40.172 6.30403V6.30403C40.172 9.78564 37.3496 12.6081 33.8679 12.6081H25.2321C24.6964 12.6081 24.2622 12.1738 24.2622 11.6382V7.95488Z"
        fill={theme.primary['200'].DEFAULT}
      />
      <path
        d="M19.4893 54.2962C19.4893 50.8146 22.3117 47.9922 25.7933 47.9922H34.4292C34.9648 47.9922 35.399 48.4264 35.399 48.962V52.6454C35.399 57.0387 31.8375 60.6002 27.4441 60.6002H25.7933C22.3117 60.6002 19.4893 57.7778 19.4893 54.2962V54.2962Z"
        fill={theme.primary['200'].DEFAULT}
      />
      <path
        d="M7.95508 42.7052C7.95508 39.3359 10.6864 36.6045 14.0558 36.6045H22.5285C23.0469 36.6045 23.4671 37.0247 23.4671 37.5431V41.0498C23.4671 45.3334 19.9946 48.8058 15.7111 48.8058H14.0557C10.6864 48.8058 7.95508 46.0745 7.95508 42.7052V42.7052Z"
        fill={theme.primary['300'].DEFAULT}
      />
      <path
        d="M36.1948 19.5509C36.1948 15.2674 39.6673 11.7949 43.9508 11.7949H45.6062C48.9755 11.7949 51.7068 14.5263 51.7068 17.8956V17.8956C51.7068 21.2649 48.9755 23.9963 45.6062 23.9963H37.1334C36.615 23.9963 36.1948 23.5761 36.1948 23.0577V19.5509Z"
        fill={theme.primary['300'].DEFAULT}
      />
      <path
        d="M46.9336 25.758C46.9336 25.2342 47.3582 24.8096 47.8821 24.8096H51.1294C55.6218 24.8096 59.2637 28.4514 59.2637 32.9438V34.913C59.2637 38.3179 56.5035 41.078 53.0986 41.078V41.078C49.6938 41.078 46.9336 38.3179 46.9336 34.913V25.758Z"
        fill={theme.primary['500'].DEFAULT}
      />
      <path
        d="M36.1948 38.7421C36.1948 38.2352 36.6058 37.8242 37.1127 37.8242H40.1963C44.5764 37.8242 48.1271 41.375 48.1271 45.7551V47.7198C48.1271 51.0148 45.456 53.686 42.161 53.686V53.686C38.866 53.686 36.1948 51.0148 36.1948 47.7198V38.7421Z"
        fill={theme.primary['300'].DEFAULT}
      />
      <path
        d="M0 26.0937C0 22.6889 2.76018 19.9287 6.16503 19.9287V19.9287C9.56988 19.9287 12.3301 22.6889 12.3301 26.0937V34.842C12.3301 35.3658 11.9054 35.7905 11.3816 35.7905H7.93088C3.55078 35.7905 0 32.2397 0 27.8596V26.0937Z"
        fill={theme.primary['500'].DEFAULT}
      />
      <path
        d="M11.9321 15.3206C11.9321 12.0256 14.6033 9.35449 17.8983 9.35449V9.35449C21.1933 9.35449 23.8644 12.0256 23.8644 15.3206V24.2984C23.8644 24.8053 23.4535 25.2162 22.9466 25.2162H19.863C15.4829 25.2162 11.9321 21.6655 11.9321 17.2854V15.3206Z"
        fill={theme.primary['300'].DEFAULT}
      />
    </svg>
  )
}

export default Logo
