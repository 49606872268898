import { Whatsapp } from '@/enums/whatsapp'

export const helpMessageBuilder = (error?: string, codAgendamento?: string): string => {
  try {
    const baseHelpMessage = `Olá, tudo bem?
Estava tentando acessar a minha consulta e encontrei o seguinte problema: ${error}
Você pode me ajudar? Meu código de agendamento é: ${codAgendamento}`

    return baseHelpMessage
  } catch {
    return ''
  }
}

export const hrefHelpSaludiaBuilder = (message?: string): string => {
  return `https://wa.me/${Whatsapp.helpSaludia}?text=${encodeURIComponent(message || '')}`
}
