import { hrefHelpSaludiaBuilder } from '@/lib/whatsapp-helper'

interface Props {
  message?: string
  label: string
}

const LinkWpp: React.FC<Props> = ({ message, label }) => {
  return (
    <div className="text-base leading-5 font-normal text-center my-3 underline">
      <a href={hrefHelpSaludiaBuilder(message)} target="_blank">
        {label}
      </a>
    </div>
  )
}

export default LinkWpp
