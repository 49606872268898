import React from 'react'
import LinkWpp from './LinkWhatsApp'
import { helpMessageBuilder } from '@/lib/whatsapp-helper'
interface Props {
  text?: string
  codAgendamento?: string
}

const ScheduleNotFound: React.FC<Props> = ({ text, codAgendamento }) => {
  const textMessage = helpMessageBuilder(text, codAgendamento)

  return (
    <>
      <main>
        <section className="mb-8">
          <picture className="flex items-center justify-center mb-[16px]">
            <img src="/images/mobile/error.svg" alt="" width={136} height={137}></img>
          </picture>
          <h1 className="text-2xl leading-7 font-bold text-[#222A45] mb-[16px] text-center">
            {text}
          </h1>
          <LinkWpp message={textMessage} label="Preciso de ajuda"></LinkWpp>
        </section>
      </main>
    </>
  )
}

export default ScheduleNotFound
